.ChainModal .Modal-content {
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 768px) {
  .ChainModal .Modal-content {
    width: calc(100% - 16px);
  }
}
