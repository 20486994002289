.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: transparent;
  display: flex !important;
  align-items: center;
  gap: 6px;
}
.chart-token-selector-pair {
  display: flex;
  align-items: center;
}
.chart-token-selector-logo {
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 2;
  background: #1d1d1f;
  border-radius: 50%;
}
.chart-token-selector-pair > img:last-child {
  margin-left: -12px;
  z-index: 1;
}
.chart-token-selector:hover::after {
  opacity: 0 !important;
}

.chart-token-selector--current {
  font-size: 1.8rem;
  margin-right: 0.2rem;
  color: white;
}

.chart-token-menu-items.menu-items {
  width: 20rem;
  top: 6rem;
  right: unset;
  left: 0;
}

.chart-token-menu-items .menu-item {
  font-size: var(--font-sm);
  height: 3.4rem;
}
