.menu-items:focus-visible {
  border: 1px solid #262638;
}
.App-header-user-address > div[aria-expanded="true"] button.address-btn {
  border-radius: 24px 24px 0 0;
}
.App-header-user-address .menu-items {
  top: 4.2rem;
  border-radius: 0 0 20px 20px;
}

.user-avatar .punk {
  border-radius: 50%;
}

.address-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  padding: 12px;
  background: #33316D;
  border-radius: 24px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.App-header-user-address .menu-item > img {
  width: 18px;
  height: 18px;
}
.user-avatar {
  display: flex;
}

.user-address {
  margin-left: 0.8rem;
  margin-right: 2.4rem;
}

@media screen and (max-width: 1350px) {
  .address-btn {
    padding: 12px 8px;
  }
  .user-address {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .user-address {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
}

.menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 15.5rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 0.8rem;
  background: #33316D;
  list-style: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  z-index: 1000;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: var(--font-base);
  color: var(--text-primary);
  padding-bottom: 1.5rem;
  font-size: var(--font-sm);
  padding: 0.8rem 1.4rem;
  transition: background-color 0.3s;
}
.menu-item:hover {
  background: #46468a !important;
  opacity: 1;
  color: var(--text-primary);
}
.menu-item > svg {
  color: var(--text-accent-dark);
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  .menu-item {
    padding: 0.8rem 0.6rem;
  }
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
