.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: var(--text-primary);
  background: var(--menu-item);
  box-shadow: 0px 20px 63px rgba(0, 0, 0, 0.04), 0px 7.7037px 20.0667px rgba(0, 0, 0, 0.03),
    0px 1.62963px 5.13333px rgba(0, 0, 0, 0.0314074);
}

.Tab-option-icon {
  opacity: 0.7;
}

.Tab.block .Tab-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  color: var(--text-primary);
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: var(--btn-bg);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: var(--btn-bg);
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-base);
  transition: color 0.3s;
}

.Tab.inline .Tab-option:hover {
  color: var(--text-accent-dark);
}

.Tab.inline .Tab-option.active {
  color: var(--text-primary);
  pointer-events: none;
}
