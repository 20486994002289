.App-header-network {
  position: relative;
  margin-left: 1.4rem;
  /* border: 1px solid var(--dark-blue-border); */
  border-radius: 50%;
  height: 3.6rem;
  display: inline-flex;
  align-items: center;
  color: white;
}

.App-header-language {
  margin-left: 0;
}

.network-dropdown {
  display: inline-flex;
  cursor: pointer;
}
.network-dropdown-items {
  min-width: 17rem;
  padding-bottom: 0.8rem;
}
.network-dropdown-items:last-child {
  padding-bottom: 0;
}

.network-dropdown-divider {
  margin-bottom: 0.8rem;
  border-bottom: 1px solid #ffffff29;
}
.network-dropdown:hover > button {
  background: linear-gradient(232.38deg, rgba(56, 56, 241, 0.4) 0%, rgba(56, 56, 241, 0) 116.81%),
    rgba(40, 40, 71, 0.32);
  cursor: pointer;
}
.network-dropdown:active > button {
  background: linear-gradient(232.38deg, rgba(56, 56, 241, 0.4) 0%, rgba(56, 56, 241, 0) 116.81%),
    rgba(40, 40, 71, 0.32);
}

.network-dropdown > button {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: none;
  overflow: hidden;
  pointer-events: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(232.38deg, rgba(56, 56, 241, 0.2) 0%, rgba(56, 56, 241, 0) 116.81%),
    rgba(40, 40, 71, 0.32);
  padding: 0 0.8rem;
}

.network-dropdown-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.language-popup .Modal-content {
  width: 32rem;
}

.network-dropdown-seperator {
  width: 1px;
  background: var(--dark-blue-border);
  margin: 0.8rem 0;
}

.menu-item-group {
  display: flex;
  align-items: center;
}

.network-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  justify-content: space-between;
}
.last-dropdown-menu {
  margin-bottom: 0.8rem;
}
.network-popup .Modal-content {
  width: 100%;
  max-width: calc(100% - 32px);
  margin: auto;
}
.network-option {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: inset 0px 0px 1rem 0.5rem rgba(27, 30, 46, 0.2);
  background: var(--btn-bg);
  border: none;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  position: relative;
  color: white;
  font-size: 2rem;
  display: grid;
  padding: 0.8rem 1.5rem;
  grid-template-columns: auto 1fr;
  text-align: left;
  overflow: hidden;
}
.network-option:hover::after {
  opacity: 1;
  transform: translate(0);
}

.network-option::after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%);
  width: 100%;
  height: 100%;
  background: var(--btn-hover);
  border-radius: 0.4rem;
  opacity: 0;
  content: "";
  z-index: -1;
  transition: opacity 0.3s, transform 0.6s;
}
.network-option:last-child {
  margin-bottom: 0;
}

.network-option img {
  width: 2.5rem;
  margin-right: 1.5rem;
}

.network-option span {
  font-size: var(--font-base);
  letter-spacing: 0.29px;
  color: #fff;
}

.network-option-img-label {
  font-size: var(--font-md);
  line-height: 2rem;
  letter-spacing: 0.29px;
  color: #fff;
}

.dropdown-label {
  color: #a0a3c4;
  padding: 0.8rem;
  font-size: 1.25rem;
}

.language-popup .Modal-body {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.language-modal-item {
  border: 1px solid var(--dark-blue-border);
  cursor: pointer;
}

.language-modal-item.active {
  border: 1px solid #a1a3c4;
}

.menu-item-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}

.network-dropdown-headings {
  padding: 0.8rem 0.8rem 0.4rem 0.8rem;
  opacity: 0.7;
  font-size: 1.25rem;
}

.network-dropdown-item-label {
  margin-left: 0.8rem;
}

.network-dropdown-list {
  display: grid;
  grid-template-columns: 1fr;
}

.menu-item:hover .menu-item-label {
  color: #eee;
}

.active-dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}
.active-dot.Arbitrum {
  background-color: #4275a8;
}
.active-dot.Avalanche {
  background-color: #e84142;
}
.network-dropdown-label {
  font-size: 1.25rem;
  margin-bottom: 0.8rem;
  opacity: 0.7;
}

.more-options {
  margin-top: 1rem;
}

@media (max-width: 10100px) {
  .App-header-network {
    margin-left: 1rem;
  }
  .App-header-language {
    margin-left: 0;
  }
}
@media (max-width: 450px) {
  .App-header-network {
    margin-left: 0.6rem;
  }
  .App-header-language {
    margin-left: 0;
  }
  .network-dropdown > button {
    padding: 0 0.5rem;
  }
  .network-dropdown.homepage-header > button {
    padding: 0 0.8rem;
  }
}
