.Exchange + .Footer-Wrapper {
  max-width: 155rem;
}

.Footer-Wrapper {
  position: relative;
  width: 100%;
  display: flex;
  max-width: 126.4rem;
  justify-content: center;
  padding: 140px 3.1rem 0;
  margin: 0 auto;
  color: var(--text-primary);
}

.Footer-Wrapper::after {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -5px;
  z-index: -1;
  content: "";
  pointer-events: none;
}

.Footer-Wrapper-Inner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 30px;
}

.Footer-Link {
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.Footer-Link:hover {
  color: var(--text-primary);
}

.Footer-Links-Wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  grid-column-start: 5;
  grid-column-end: 13;
  margin-bottom: 30px;
  color: #545479;
}

.Footer-Links-Column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}

.Footer-Links-Label {
  font-family: "PT serif";
  font-size: 18px;
  line-height: 24px;
  color: var(--text-primary);
}

.Footer-Socials-Wrapper {
  display: flex;
  justify-content: flex-start;
  column-gap: 40px;
  row-gap: 24px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  color: #545479;
}

.Footer-Social-Item {
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #545479;
  opacity: 0.8;
}

.Footer-Social-Item > img {
  width: 24px;
  height: 24px;
}

.Footer-Social-Item:hover {
  color: var(--text-primary);
  opacity: 1;
}

.Copyright-Row {
  grid-row-start: 2;
  grid-column: span 12;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  column-gap: 30px;
  padding: 16px 0 30px;
  align-items: center;
}
.Footer-Copyright-Text {
  grid-column: span 6;
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
  color: #545479;
}

.Footer-Audit-Text {
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
  transition: all 0.3s;
}

.Footer-Audit-Wrapper {
  grid-column-start: 11;
  grid-column-end: 13;
  max-width: 153px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 7px 0;
  border-radius: 20px;
  white-space: nowrap;
  color: #545479;
  transition: border-color 0.3s;
}

.Footer-Audit-Wrapper:hover {
  color: var(--text-primary);
}

/* &:hover ${FooterAuditText} {
  opacity: 1;
} */

@media screen and (max-width: 1200px) {
  .Footer-Links-Wrapper {
    grid-column: span 12;
    margin-bottom: 48px;
  }

  .Footer-Socials-Wrapper {
    grid-row-start: 2;
    grid-column: span 12;
    column-gap: 48px;
    margin-bottom: 24px;
  }

  .Copyright-Row {
    grid-row-start: 3;
  }

  .Footer-Audit-Wrapper {
    grid-column-start: 10;
  }
}

@media screen and (max-width: 980px) {
  .Footer-Wrapper {
    padding-top: 100px;
    margin-bottom: 0;
  }

  .Footer-Wrapper::after {
    min-height: 120%;
  }

  .Copyright-Row {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 768px) {
  .Footer-Links-Wrapper {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 48px;
    margin-bottom: 48px;
  }

  .Footer-Wrapper {
    padding: 100px 0.8rem 0;
  }

  .Footer-Socials-Wrapper {
    justify-content: center;
  }

  .Copyright-Row {
    padding: 14px 10px;
    width: calc(100% + 20px);
    margin: 0 -10px;
  }

  .Footer-Audit-Wrapper {
    grid-column: span 6;
    margin-left: auto;
  }
}
