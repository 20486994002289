.connect-wallet-btn {
  position: relative;
  z-index: 2;
  background: transparent;
  padding: 1.2rem 4.4rem;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  border: none;
  color: #fff;
  font-size: var(--font-sm);
  cursor: pointer;
  background: var(--btn-bg);
  border-radius: 2.4rem;
  
  .btn-label {
    font-weight: 400;
    font-size: var(--font-sm);
    letter-spacing: 0;
  }

  .btn-icon {
    display: none;
  }
}

.connect-wallet-btn::after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%);
  width: 100%;
  height: 100%;
  background: var(--btn-hover);
  border-radius: 0.4rem;
  opacity: 0;
  content: "";
  z-index: -1;
  transition: opacity 0.3s, transform 0.6s;
}

.connect-wallet-btn:hover::after {
  opacity: 1;
  transform: translate(0);
}
.connect-wallet-btn:actvie::after {
  opacity: 1;
  transform: translate(0);
}


@media screen and (max-width: 1400px) {
  .connect-wallet-btn {
    padding: 1.2rem 2rem;
  }
}
@media screen and (max-width: 1100px) {
  .connect-wallet-btn {
    background: #3838F1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .connect-wallet-btn .btn-label {
    display: none;
  }
  .connect-wallet-btn .btn-icon {
    display: block;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 0.5rem 0.8rem;
  }
}

@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
    .btn-icon {
      display: none;
    }
    .btn-label {
      margin-left: 0px;
    }
  }
}
