.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox.disabled.selected .Checkbox-icon-img {
  opacity: 0.4;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}


.Checkbox .Checkbox-icon {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: rgba(111, 107, 231, 0.5);
}

.Checkbox .Checkbox-icon--boxView {
  border-radius: 0.4rem;
  background: #474698;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}
.Checkbox .Checkbox-active-icon--boxView {
  border-radius: 0.4rem;
  width: 1.4rem;
  height: 1.4rem;
  background: transparent;
}
.Checkbox-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}
.Checkbox-active-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--text-primary);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-sm);
}

.Checkbox:hover .Checkbox-active-icon {
  opacity: 0.5;
}

.Checkbox:hover .Checkbox-active-icon.Checkbox-active-icon--boxView {
  opacity: 0;
}

.Checkbox.selected:hover .Checkbox-active-icon {
  opacity: 1;
}

.Checkbox-icon.Checkbox-active .Checkbox-active-icon {
  opacity: 1;
}
