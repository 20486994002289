.card {
  border: 1px solid transparent;
  border-radius: 0.8rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(176.7deg, rgba(56, 56, 241, 0.16) -57.98%, rgba(56, 56, 241, 0) 97.38%), rgba(40, 40, 71, 0.32);
}

.card-header {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem;
}

.card-divider {
  height: 1px;
  background: #23263b;
}
.card-body {
  padding: 1.5rem;
}
