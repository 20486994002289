.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-header.large {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.App-header.large .App-header-links {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 8px;
  gap: 20px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background: radial-gradient(100.42% 1243.1% at 50% 100%, rgba(56, 56, 241, 0.16) 0%, rgba(56, 56, 241, 0) 100%),
    rgba(60, 60, 91, 0.48);
  border-radius: 40px;
}

.App-header-link-container > a {
  padding: 5px 12px;
  display: flex;
  border-radius: 20px;
  transition: all 0.3s ease;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-primary);
}

.App-header-link-container > a.active,
.App-header-link-container > a:hover {
  background: #28285b;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.App-header-link-inner {
  position: absolute;
  right: -68px;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .App-header-link-inner {
    display: none;
  }
}

.App-header .App-header-link-inner > a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  padding: 8px;
  background: radial-gradient(100.42% 1243.1% at 50% 100%, rgba(56, 56, 241, 0.16) 0%, rgba(56, 56, 241, 0) 100%),
    rgba(60, 60, 91, 0.48);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  overflow: hidden;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  transition: transform 0.5s;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
}

.App-header-link-inner > a::before {
  position: absolute;
  top: 8px;
  left: 8px;

  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border-radius: 50%;
  background: var(--btn-hover);
  opacity: 0;
  content: "";
  z-index: 1;
  transition: opacity 0.5s;
}

.App-header-link-inner > a:hover::before {
  opacity: 1;
}

.App-header-link-inner > a::after {
  position: absolute;
  top: 8px;
  left: 8px;

  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border-radius: 50%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: linear-gradient(87.2deg, rgba(255, 240, 205, 0) 0%, rgba(255, 240, 205, 0.3) 100%), #3838f1;
  content: "";
}

.App-header-link-inner > a span {
  position: relative;
  z-index: 2;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-drawer {
  opacity: 1;
  background: #111111;
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  padding: 16px;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.App-header-drawer .App-background {
  width: 780px;
  left: 50%;
  top: -20%;
  min-height: auto;
  height: 80%;
  transform: translateX(-50%);
}
.App-header-drawer .App-background-ball {
  width: 700px;
  height: 700px;
  right: -330px;
  top: auto;
  bottom: -20%;
}
.App-header-drawer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.App-header-drawer .App-header-link-container .a,
.App-header-drawer .App-header-link-container a {
  font-size: 36px;
  line-height: 50px;
  color: var(--text-accent-dark);
  padding: 0.6rem 0;
  text-decoration: none;
  box-shadow: none;
  display: block;
}

.App-header-drawer .App-header-link-container .a:hover,
.App-header-drawer .App-header-link-container .a:focus,
.App-header-drawer .App-header-link-container .a:active,
.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: transparent;
  color: white;
}
.App-header-drawer .App-header-links {
  margin-top: 90px;
}
.App-header-drawer .App-header-links-header {
  height: 6.2rem;
  display: flex;
  align-items: center;
  z-index: 3;
}

.App-header-container-left {
  display: flex;
  align-items: center;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: 2; */
}

.App-header-menu-icon-block {
  margin-right: 1.2rem;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 0.9rem 1rem;
}

.App-header-container-center {
  padding-right: 68px;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  font-size: 1.7rem;
  padding: 1.5rem;
  margin-left: -1.5rem;
  display: flex;
  margin-right: 1.2rem;
}

.App-header-link-main img {
  display: inline-block;
  height: 2.15rem;
  vertical-align: middle;
}

.App-header-link-main img.small {
  display: none;
}

.App-header .a,
.App-header a {
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.App-header .a.active,
.App-header a.active {
  pointer-events: none;
  opacity: 1;
  color: #eee;
}

.App-header .a:hover,
.App-header a:hover {
  opacity: 1;
  color: #eee;
}

.App-header-container-left .a,
.App-header-container-left a {
  padding: 1.8rem 1.7rem;
  display: block;
  color: #a0a3c4;
  font-size: var(--font-base);
  line-height: 1.8rem;
  font-weight: normal;
}

.App-header-container-left .a.active,
.App-header-container-left a.active {
  color: white;
}

.App-header-container-right {
  display: flex;
  align-items: center;
}

.App-header-user {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.App-header-user-price {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 70px;
}
.App-header-user-connect {
  display: none;
}

@media screen and (max-width: 1350px) {
  .App-header.large .App-header-links {
    gap: 10px;
  }

  .App-header-link-container > a {
    padding: 5px 8px;
  }

  .App-header-container-center {
    padding-right: 58px;
  }

  .App-header .App-header-link-inner > a {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .App-header-container-center {
    padding-right: 0;
  }

  .App-header-link-container > a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  .App-header-user-connect {
    display: flex;
  }

  .App-header-user-price {
    margin-right: 12px;
    font-size: 14px;
  }

  .App-header-user-price-img {
    width: 16px;
    height: 16px;
  }
}
.App-header-user-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin-left: 16px;
  height: 40px;
  background: linear-gradient(232.38deg, rgba(56, 56, 241, 0.2) 0%, rgba(56, 56, 241, 0) 116.81%),
    rgba(40, 40, 71, 0.32);
  border-radius: 50%;
  cursor: pointer;
}
.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-header-user-netowork {
  margin-right: 2.4rem;
}

.App-header-user-link {
  margin-right: 2.4rem;
}

.App-header-user-address {
  /* border: 1px solid #ffffff29; */
  /* height: 3.6rem; */
  /* border-radius: 0.4rem; */
  display: inline-flex;
  align-items: center;
  /* color: white; */
  position: relative;
}

@media (max-width: 1100px) {
  .App-header-user-address {
    padding: 0 0px;
  }

  .App-header-user-link {
    margin-right: 1.6rem;
  }

  .App-header-user-netowork {
    margin-right: 1.6rem;
  }

  .App-header-link-home {
    display: block;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 2.6rem 1.6rem 2rem;
  }

  .App-header.small.active {
    z-index: 1000;
  }

  a.App-header-user-account {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main img.small {
    display: inline-block;
  }
}

@media (max-width: 450px) {
  .App-header-user-link {
    margin-right: 0.6rem;
  }

  .App-header-user-netowork {
    margin-right: 0.6rem;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  .App-header-menu-icon {
    margin: 0.4rem 0.5rem;
  }

  .App-header.small {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .App-header .App-header-link-main {
    padding: 0.155rem !important;
    margin-left: 0rem !important;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .App-header-drawer {
    background: #0e0f1f;
  }
}
