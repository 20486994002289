.Referrals {
  padding-top: 4.65rem;
}

.section-center {
  margin: 0 auto;
  text-align: center;
}
.mt-large {
  margin-top: 3.1rem;
}
.mt-medium {
  margin-top: 1.5rem;
}
.Referrals-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media screen and (max-width: 1000px) {
  .Referrals-box-wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 24px;
  }
}
.Referrals .section-title-block {
  width: auto;
}
.referral-tab-container {
  position: relative;
  z-index: 2;
}
.referral-tab-container .Tab.block .Tab-option {
  padding: 8px 32px;
  font-size: 16px;
}
.referral-card {
  position: relative;
  padding: 2.4rem;
  border-radius: 1.3rem;
  max-width: 59.2rem;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.16) -42.11%, rgba(56, 56, 241, 0) 162.53%), rgba(40, 40, 71, 0.32);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 0.8rem;
}
.referral-card .title {
  font-weight: 500;
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 0;
}
.referral-card .sub-title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.1625rem;
}
.referral-card .card-action {
  margin-top: 3.1rem;
}

.referral-card .card-action input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
  margin-right: 1.5rem;
}
.referral-body-container .list {
  margin-top: 1.6rem;
}
.reward-history {
  margin-top: 3.1rem;
}
.active-referral-code .edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.active-referral-code .edit span {
  margin-right: 1.5rem;
}
.active-referral-code .edit svg {
  cursor: pointer;
}
.active-referral-code .edit svg:hover {
  opacity: 0.75;
}
.active-referral-code .tier {
  margin-top: 0.775rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-referral-code .tier span {
  font-size: var(--font-sm);
  color: var(--text-primary);
  margin-right: 0.775rem;
}
.active-referral-code .tier a {
  text-decoration: none;
  font-size: 0;
}
.mb-sm {
  margin-bottom: 1.5rem;
}
.edit-referral-modal {
  width: 31rem;
}
.referral-code-edit span {
  margin-right: 0.775rem;
}
.referral-code-edit svg {
  opacity: 0.75;
  cursor: pointer;
}

.referral-code-edit svg:hover {
  opacity: 1;
}
.create-referrar-code {
  text-align: center;
  max-width: 50rem;
  margin: 3.1rem auto;
}
.transparent-btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  padding: 0.75rem 1.25rem;
  color: white;
  font-size: var(--font-sm);
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.transparent-btn:hover {
  background: var(--dark-blue-hover);
}

.transparent-btn:active {
  background: var(--dark-blue-active);
}

.referral-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.referral-table-header .title {
  margin: 0;
}
.referral-table-header .sub-title {
  color: var(--text-accent-dark);
  font-size: var(--font-sm);
  margin-left: 0.775rem;
}

.referral-table-header .ml-small {
  margin-left: 0.775rem;
}
.create-referrar-code form {
  display: flex;
  align-items: inherit;
  justify-content: center;
  margin: 3.1rem 0;
}
.create-referrar-code form input {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  padding: 1.1625rem;
  font-size: var(--font-base);
  width: 40rem;
}
.create-referrar-code form button {
  margin-left: 1.5rem;
}

.referral-stats {
  margin: 0 auto;
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

.text-center {
  text-align: center;
}

.info-card {
  background: linear-gradient(176.7deg, rgba(56, 56, 241, 0.16) 2.83%, rgba(56, 56, 241, 0) 279.44%),
    rgba(40, 40, 71, 0.32);
  border-radius: 0.8rem;
  display: flex;
  padding: 2.4rem;
  justify-content: flex-start;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.info-card .label {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  color: var(--text-accent-dark);
}
.info-card .data {
  font-size: var(--font-lg);
  font-weight: normal;
  padding: 0;
  margin: 1.5rem 0;
}
.info-card .info-card-question-icon {
  vertical-align: middle;
  font-size: var(--font-sm);
  opacity: 0.8;
}

.table-referral-code {
  align-items: center;
  display: flex;
}

.table-referral-code .referral-text {
  margin-right: 0.775rem;
  font-family: monospace;
}

.table-referral-code .referral-code-icon {
  margin-right: 1rem;
  cursor: pointer;
  display: inline-flex;
  color: var(--text-accent-dark);
}

.table-referral-code .referral-code-icon:hover {
  color: var(--text-primary);
}

.table-referral-code .info {
  margin-left: 0.775rem;
}

.referral-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: var(--font-base);
}
.referral-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: var(--text-accent-dark);
  text-transform: uppercase;
}
.referral-table th,
.referral-table td {
  padding: 0.625em;
  text-align: left;
}

.table-referral-code p {
  margin: 0;
  margin-left: 0.775rem;
  padding: 0;
}
.error {
  margin: 0;
  padding: 0.775rem 0 1.5rem;
  color: #fa3c58;
  font-size: var(--font-sm);
}

.table-wrapper {
  display: block;
  width: 100%;
  font-size: var(--font-base);
}

.empty-message {
  margin: 4.65rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  background: linear-gradient(176.7deg, rgba(56, 56, 241, 0.16) -390.7%, rgba(56, 56, 241, 0) 97.38%),
    rgba(40, 40, 71, 0.32);
  font-size: var(--font-base);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 600px) {
  .empty-message {
    margin: 2rem 0;
  }
}
.confirm-checkbox .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
}

@media (max-width: 800px) {
  .referral-table {
    width: auto;
    margin-bottom: 1.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
  }
  .table-head {
    white-space: nowrap;
  }
}
@media (max-width: 500px) {
  .referral-table-header .sub-title {
    display: block;
    margin-left: 0;
    margin-top: 2px;
  }
}
