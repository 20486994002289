.PositionDropdown-dots-icon {
  font-size: 1rem;
  border: none;
  color: var(--text-accent);
  display: inline-flex;
  align-items: center;
  background: transparent;
  border-radius: 2rem;
  padding: 0.5rem;
  margin-top: 0;
}

.PositionDropdown-dots-icon:hover {
  color: rgba(255, 255, 255, 1);
}

.PositionDropdown-extra-options {
  position: relative;
}

.PositionDropdown-extra-options .menu-items {
  top: 1rem;
  min-width: 15.5rem;
}
