.Exchange {
  max-width: 155rem;
  margin: auto;
  justify-content: space-between;
}

.Exchange-trigger-order-info,
.Exchange-trigger-order-info a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid var(--border-light);
}

.Exchange-list-tab-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 1.6rem;
}
.Exchange-list-tab-container .Tab {
  display: flex;
  align-items: center;
  gap: 4.8rem;
}
.Exchange-list-tab-container .Tab .Tab-option .count {
  border-radius: 50%;
  padding: 0.2rem 0.6rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  font-size: 1rem;
  color: var(--text-primary);
  background: var(--control-bg);
  line-height: 1.4rem;
}

.Exchange-should-show-position-lines {
  font-size: var(--font-sm);
}
.Exchange-should-show-position-lines .active {
  opacity: 1;
}

.position-loading-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-settings-row {
  margin-bottom: 0.8rem;
}

.Exchange-settings .App-cta {
  margin-top: 1.5rem;
}

.Exchange-content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.5rem;
  padding: 3.1rem;
  padding-top: 0rem;
  margin-top: -1.5rem;
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-lists.large {
  display: block;
}

.Exchange-lists.small {
  display: none;
}

.Exchange-right {
  display: grid;
  grid-template-rows: auto 1fr;
}

.Exchange-leverage-slider-settings {
  font-size: var(--font-sm);
  margin-bottom: 0.8rem;
}

.Exchange-leverage-slider-settings .Checkbox-label {
  flex-grow: 1;
}
.Exchange-leverage-slider-settings .Checkbox-label .muted {
  display: block;
  width: 100%;
  color: var(--text-accent-dark);
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 0.4rem;
}

.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 1.05rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-sm);
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 49.6rem;
}

.ExchangeChart-main-price {
  font-size: 1.5rem;
}
.ExchangeChart-info-price {
  font-size: 1.2rem;
}
.ExchangeChart-title {
  font-size: 2.15rem;
}

.ExchangeChart-info-label {
  font-size: 1.2rem;
  color: var(--text-accent);
}

.ExchangeChart-top-inner {
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 4rem;
  align-items: center;
  padding-left: 0.3rem;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  z-index: 2;
  padding: 0.9rem 0.31rem;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.12) -119.64%, rgba(56, 56, 241, 0) 253.57%),
    rgba(40, 40, 71, 0.32);
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 6.975rem;
  z-index: 1;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.12) -15.91%, rgba(56, 56, 241, 0) 162.53%),
    rgba(40, 40, 71, 0.32);
}
.ExchangeChart-bottom-controls {
  display: inline-block;
}
.ExchangeChart-bottom-controls .Tab {
  border-radius: 0.8rem;
  padding: 0.8rem;
  gap: 8px;
}
.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  color: var(--text-accent-dark);
  opacity: 1;
  transition: color 0.3s;
}

.ExchangeChart-bottom-controls .Tab.block .Tab-option.active {
  background: var(--control-bg);
}

.ExchangeChart-bottom-controls .Tab .Tab-option:hover {
  color: var(--text-primary);
  background: transparent;
}
.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}
.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: var(--font-sm);
  padding: 0.8rem 0;
  margin-left: 3.1rem;
  white-space: nowrap;
  z-index: 2;
}
.ExchangeChart-bottom-stats-label {
  color: var(--text-accent-dark);
}
.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}
.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}
.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}
.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}
.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}
.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0.8rem;
  left: 0;
  right: 0;
  top: 0.8rem;
}

.Exchange-price-tooltip {
  font-size: var(--font-sm);
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Exchange-price-time {
  font-size: 1.25rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 38rem;
  position: absolute;
  max-height: 80vh;
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
}
.PositionEditor .Modal-title-bar {
  margin: 2.4rem 3rem;
}
.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
  margin: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 1.5rem;
}

.PositionEditor .Exchange-swap-section {
  box-shadow: 0px 11px 20px rgba(0, 0, 0, 0.16), 0px 5.56875px 10.1063px rgba(0, 0, 0, 0.108),
    0px 2.2px 14.95px rgba(0, 0, 0, 0.08), 0px 0.48125px 5.31875px rgba(0, 0, 0, 0.052);
}

.PositionEditor .Tab {
  margin-bottom: 2rem;
  gap: 0;
}

.PositionEditor-token-symbol {
  font-size: 2.1rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list-modal .Modal-content {
  width: 36rem;
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 8px;
}

.active-order-tooltip {
  background: var(--dark-blue-hover);
  padding: 0.75rem;
  border-radius: var(--border-radius-sm);
  white-space: normal;
}
.active-order-tooltip > .active-oredr-error {
  margin-top: 0.5rem;
}

.order-error span {
  text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Exchange-list .App-card {
  margin-bottom: 1.5rem;
}

.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.465rem;
  font-size: var(--font-sm);
}

.Exchange-list.small {
  display: none;
}

button.Exchange-list-action {
  font-size: var(--font-base);
  padding: 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  color: var(--text-accent);
  transition: all 0.3s;
}
button.Exchange-list-action svg {
  width: 1.8rem;
  height: 1.8rem;
}
button.Exchange-list-action:hover {
  color: rgba(255, 255, 255, 1);
}
.Exchange-list-wrap {
  padding: 2.4rem;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.16) -345.88%, rgba(56, 56, 241, 0) 100%),
    rgba(40, 40, 71, 0.32);
}
table.Exchange-list {
  border-collapse: collapse;
  border-radius: 0.4rem;
}

.Exchange-list-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
table.Exchange-list.Orders th:first-child,
table.Exchange-list.Orders td:first-child {
  padding: 0;
}
table.Exchange-list th {
  font-weight: normal;
  text-align: left;
  font-size: 1.3rem;
  padding: 1.05rem;
  padding-left: 1.5rem;
}
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  font-size: 1.4rem;
  padding: 1.5rem;
}
table.Exchange-list.Orders th,
table.Exchange-list.Orders td {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid var(--border-light);
}

table.Exchange-list tr:not(:first-child):only-child {
  border-bottom: none;
}

/* table.Exchange-list tr:hover {
  background: linear-gradient(267.26deg, #323265 0%, rgba(50, 50, 101, 0) 100%), #28285b;
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
  border-radius: 8px;
} */

table.Exchange-list tr.Exchange-list-header {
  background: none;
  font-size: var(--font-md);
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list th {
  color: var(--text-accent);
}

.Exchange-leverage-box {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box-info {
  margin-bottom: 1.05rem;
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  margin-bottom: 1rem;
}

.Exchange-info-row.top-line {
  border-top: 1px solid var(--border-light);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Exchange-info-label {
  margin-right: 0.8rem;
}
.Exchange-info-value-warning {
  color: #fa3c58;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: var(--font-sm);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.7;
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
}

.Exchange-swap-usd {
  display: inline-block;
  font-size: var(--font-sm);
}

.Exchange-swap-box {
  width: 41.85rem;
  display: grid;
  position: relative;
}

.Exchange-swap-market-box-title {
  font-size: 1.8rem;
  margin-bottom: 1.25rem;
}

.Exchange-swap-box-inner {
  padding: 2.4rem;
  background: linear-gradient(180.16deg, rgba(56, 56, 241, 0.12) 0.13%, rgba(56, 56, 241, 0) 78.7%),
    rgba(40, 40, 71, 0.32);
}

.Exchange-swap-market-box {
  padding: 2.4rem;
  margin-top: 1.25rem;
  padding-bottom: 1.5rem;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.16) -434.04%, rgba(56, 56, 241, 0) 94.17%),
    rgba(40, 40, 71, 0.32);
}

.Exchange-swap-market-box .App-card-divider {
  margin: 0 -1.5rem 1.5rem;
}

.Exchange-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Exchange-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Exchange-swap-box .TokenSelector .Modal-content,
.Selector .Modal-content {
  width: 41.85rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 0;
  border: none;
}
.Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
  scroll-margin-right: 38.75rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
}

.Exchange-swap-box .Tab {
  margin-bottom: 1.6rem;
}

.Exchange-swap-option-tabs.Tab {
  margin-bottom: 2.4rem;
}

.Exchange-swap-section {
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 0.4rem;
  background: var(--menu-item);
  box-shadow: 0px 11px 92px rgba(0, 0, 0, 0.16), 0px 5.56875px 40.1063px rgba(0, 0, 0, 0.108),
    0px 2.2px 14.95px rgba(0, 0, 0, 0.08), 0px 0.48125px 5.31875px rgba(0, 0, 0, 0.052);
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  padding: 1.05rem;
}

.Exchange-swap-order-type-tabs {
  margin-top: 1.5rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.155rem;
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.8rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  padding-bottom: 1.25rem;
}

.Exchange-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.31rem;
}

.Exchange-swap-message {
  font-size: var(--font-sm);
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Exchange-swap-input-container {
  position: relative;
  overflow: hidden;
}

.Exchange-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 2px;
  padding: 0.465rem 0.8rem;
  color: #fff;
  border-radius: 20px;
  z-index: 1;
  cursor: pointer;
  font-size: var(--font-sm);
  background: #363683;
  transition: background-color 0.3s;
}

.Exchange-swap-max:hover {
  background: var(--text-accent-dark);
}

.Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.Exchange-leverage-box .TokenSelector-caret {
  font-size: var(--font-base);
  margin-left: 0.31rem;
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: var(--font-lg);
  width: 100%;
  padding-right: 2rem;
}
input.Exchange-swap-input.small {
  max-width: 14.725rem;
}

.Exchange-swap-button-container {
  padding-top: 0.31rem;
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
  opacity: 0.7;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -2.6rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exchange-swap-ball:hover {
  background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%);
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  opacity: 1;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Exchange-list-title {
  /* width: 3.1rem; */
  display: inline-flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  line-height: 1
}

.Exchange-list-info-label {
  font-size: var(--font-sm);
  margin-top: 0.31rem;
}

.Exchange-list-muted {
  font-size: var(--font-sm);
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.8rem;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 2.325rem;
}
.Confirmation-box-main > div:first-child,
.Confirmation-box-main > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 1.6rem;
  border-radius: 0.4rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background: #28285a;
  box-shadow: 0px 11px 92px rgba(0, 0, 0, 0.16), 0px 5.56875px 40.1063px rgba(0, 0, 0, 0.108),
    0px 2.2px 14.95px rgba(0, 0, 0, 0.08), 0px 0.48125px 5.31875px rgba(0, 0, 0, 0.052);
}
.Confirmation-box-type {
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  background: linear-gradient(87.2deg, rgba(255, 240, 205, 0) 0%, rgba(255, 240, 205, 0.3) 100%), #3838f1;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.Confirmation-box-main-icon {
  position: relative;
  z-index: 2;
  margin: -0.5rem auto;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: var(--control-bg);
}
.Confirmation-box-main-icon:after {
  content: "↓";
  font-size: 1.4rem;
}
.Confirmation-box-main-icon.dot:after {
  content: "•";
  font-size: 1.4rem;
}

.Confirmation-box .Modal-content {
  width: 36rem;
}

.Confirmation-box-row {
  margin-top: 1.5rem;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}
.cancel-order-btn {
  margin-right: 1.5rem;
  background: none;
  border: none;
  color: white;
  padding: 0;
}
.cancel-order-btn:hover {
  opacity: 0.8;
}
.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

@media (max-width: 1500px) {
  .Exchange-swap-box {
    width: 38.75rem;
  }
}

@media (max-width: 1300px) {
  .Exchange-list.large {
    display: none;
  }
  .Exchange-list-wrap {
    display: none;
  }
  .Exchange-list.small {
    display: table;
  }
}

@media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0.8rem;
    left: 0;
    right: 0;
    top: 3.875rem;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 38.75rem;
  }

  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.large {
    display: none;
  }

  .Exchange-lists.small {
    display: block;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .order-error-message {
    white-space: pre-line;
    display: block;
    margin-bottom: 10px;
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Exchange-swap-box .Selector .Modal-content {
    /* display: block;
    margin: 0;
    padding: 1.5rem;
    box-sizing: border-box;
    height: auto;
    border: 1px solid #1c0e6a;
    width: 100%; */
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }

  .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(70vh - 8.525rem);
    scroll-margin-right: 38.75rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 0.5rem;
    margin: 1.5rem 0.8rem 1.5rem 1.5rem;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Exchange-swap-input {
    max-width: 15.5rem;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: 1.25rem;
  }
}

@media (max-width: 700px) {
  .Exchange-list-tab-container {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .Exchange-list-tab-container .Tab {
    gap: 1rem;
  }
  .PositionEditor .Modal-content {
    width: 90vw;
  }

  .ExchangeChart-top-inner {
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.8rem;
  }

  .ExchangeChart-title {
    font-size: 1.85rem;
  }

  .ExchangeChart-additional-info {
    display: none;
  }

  .Exchange-content {
    padding: 0.8rem;
    padding-top: 1.5rem;
  }
  .Exchange-swap-box-inner {
    padding: 1.6rem;
  }
  .Exchange-swap-market-box {
    padding: 1.6rem;
  }
  .Exchange-swap-box .TokenSelector .Modal {
    margin: 0 1.5rem;
  }
  .Exchange {
    padding-top: 1.5rem !important;
  }
}
