.TradeHistory.App-Box {
  padding: 2.4rem;
  margin-bottom: 0.8rem;
  border-radius: 0.8rem;
  background: linear-gradient(180deg, rgba(56, 56, 241, 0.16) -345.88%, rgba(56, 56, 241, 0) 100%), rgba(40, 40, 71, 0.32);
}

@media (max-width: 600px) {
  .TradeHistory.App-Box {
    padding: 2rem 1.6rem;
  }
}

.TradeHistory-row {
  padding-top: 0.8rem;
  border-radius: 0 !important;
  padding-bottom: 0.8rem;
  font-size: var(--font-sm);
  border-bottom: 1px solid var(--border-light);
}

.TradeHistory-time {
  color: #6F6FB8 !important;
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}
