.input-form {
  max-width: 38.75rem;
}

.input-row {
  margin-bottom: 1.5rem;
}

.input-label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
}

.text-input {
  border: 1px solid var(--border-light);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
}

.text-input::placeholder {
  color: var(--text-light);
}
